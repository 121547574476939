import Cookies from "js-cookie";

const tokenKey = 'token'

export function fnGetToken() {
	return Cookies.get(tokenKey) === undefined ? '' : Cookies.get(tokenKey)
}

export function fnSetToken(token) {
	var curWwwPath = window.document.location.href;
	if (curWwwPath.indexOf('127.0.0.1') !== -1) {
		return Cookies.set(tokenKey, token, {
			domain: "127.0.0.1",
			expires: 30
		})
	}
	return Cookies.set(tokenKey, token, {
		domain: "zhaoyizhe.com",
		expires: 30
	})
}

export function fnRemoveToken() {
	var curWwwPath = window.document.location.href;
	if (curWwwPath.indexOf('127.0.0.1') !== -1) {
		return Cookies.remove(tokenKey, {
			domain: "127.0.0.1"
		})
	}
	return Cookies.remove(tokenKey, {
		domain: "zhaoyizhe.com"
	})
}