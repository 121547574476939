import request from '../util/request.js'

// 合成
export function subTask(fyId,textarea, SDPRatio, noise, noisew, length,id,styleName) {
    return request({
        url: '/voice/xyVoice/v3',
        method: 'post',
        data: {
            "fyId":fyId,
            "text": textarea,
            "SDPRatio": SDPRatio,
            "noise": noise,
            "noisew": noisew,
            "length": length,
            "id":id,
            "styleName":styleName
        }
    })
}