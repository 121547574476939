<template>
    <div style="width: 90vw;margin: 0 auto;">

        <div style="margin: 10px 0;text-align: right;">
            <el-button-group>
                <el-button @click="synthesis" :loading="isGenerateWork" plain round icon="el-icon-mic">生成配音</el-button>
                <el-button @click="downloadVoiceResult" v-if="synthesisResult!==''" plain round icon="el-icon-postcard">
                    下载配音
                </el-button>
                <!--                <el-button plain round icon="el-icon-postcard">下载字幕</el-button>-->
            </el-button-group>
        </div>


        <div class="inputSetting">
            <el-input :autosize="{ minRows: 5, maxRows: 10}" :show-word-limit="true" maxlength="5000" type="textarea"
                      placeholder="请输入待生成文本"
                      v-model="textarea">
            </el-input>
            <div style="text-align: right;font-size: 16px;margin: 5px;">
                <el-tooltip class="item" effect="light" content="自动按符号换行，切分成一句一段格式" placement="top-start">
                    <i @click="geshihua()" class="el-icon-document"></i>
                </el-tooltip>
            </div>
        </div>

        <el-collapse>
            <el-collapse-item name="1">
                <template slot="title">
                    <div style="font-weight: bold;"><i style="font-size: 14px;font-weight: bold;margin-right: 3px;"
                                                       class="el-icon-set-up"></i>合成高级设置
                    </div>
                </template>
                <div style="font-size: 14px;">
                    <div class="block" style="display: flex;">
                        <div style="padding: 5px;">音高比率</div>
                        <div style="flex: 1;margin-left: 10px;">
                            <el-slider show-input input-size="mini" :show-input-controls="false" v-model="SDPRatio"
                                       :format-tooltip="formatTooltip" @change="saveLocal('SDPRatio')"></el-slider>
                        </div>
                    </div>

                    <div class="block" style="display: flex;">
                        <div style="padding: 5px;">噪声比率</div>
                        <div style="flex: 1;margin-left: 10px;">
                            <el-slider show-input input-size="mini" :show-input-controls="false" v-model="noise"
                                       :format-tooltip="formatTooltip" @change="saveLocal('noise')"></el-slider>
                        </div>
                    </div>

                    <div class="block" style="display: flex;">
                        <div style="padding: 5px;">广义噪声比率</div>
                        <div style="flex: 1;margin-left: 10px;">
                            <el-slider show-input input-size="mini" :show-input-controls="false" v-model="noisew"
                                       :format-tooltip="formatTooltip" @change="saveLocal('noisew')"></el-slider>
                        </div>
                    </div>

                    <div class="block" style="display: flex;">
                        <div style="padding: 5px;">语速</div>
                        <div style="flex: 1;margin-left: 10px;">
                            <el-slider :max="lengthMax" show-input input-size="mini" :show-input-controls="false"
                                       v-model="length"
                                       :format-tooltip="formatTooltip" @change="saveLocal('length')"></el-slider>
                        </div>
                    </div>

                    <div v-if="faYinRenItem.platform==='MS'" class="block" style="display: flex;">
                        <div style="padding: 5px;">发音风格</div>
                        <div style="flex: 1;margin-left: 10px;">
                            <el-select size="mini" style="width: 100%;padding-top: 5px;" v-model="styleName" placeholder="发音风格">
                                <el-option v-for="(item,index) in voiceStyle" v-bind:key="index" :label="item.desc"
                                           :value="item.name"></el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>
        <div style="margin:5px 0;width: 100%;">
            <el-card class="box-card">
                <div slot="header">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <el-avatar :size="avatarSize" :src="faYinRenItem.avatar"/>
                        <span style="font-size: 12px;font-weight: bold;margin-left: 8px;">{{faYinRenItem.name}}</span>
                        <div style="flex: 1; text-align: right;font-weight: bold;"><i @click="fayinrenDrawer=true"
                                                                                      class="el-icon-more"></i>
                        </div>
                    </div>
                </div>
                <div class="text item" style="margin: 10px 0;font-size: 12px;text-align: center;">
                    {{faYinRenItem.desc}}
                </div>
                <div class="bottom clearfix" style="margin-bottom: 10px;">
                    <div style="text-align: left;font-size: 12px;color: #8c939d;margin: 5px 0;">
                        <i class="el-icon-info"/>可根据实际情况调整语速等参数
                        <br/><i class="el-icon-info"/>多音字可用同音字代替
                        <br/><i class="el-icon-info"/>个别不流畅语句可适当灵活修改文案
                    </div>
                    <div style="float: right;margin-bottom: 10px;display: flex;">
                        <div style="font-size: 12px;color: #8c939d;margin-right: 5px;padding-top: 5px;">
                            {{generateStatusText}}
                        </div>
                    </div>
                    <audio v-if="synthesisResult!==''" style="width: 100%;height: 30px;margin-top: 15px;"
                           controls=""
                           preload="metadata" :src="synthesisResult" data-testid="Output Audio-audio"
                           class="svelte-pq78xp"></audio>
                </div>
            </el-card>
        </div>

        <el-dialog :visible.sync="fayinrenDrawer" model="false" title="声音广场" width="60%">
            <div id="fayinren" style="display: flex; flex-wrap: wrap;height: 60vh;overflow-y: auto;">
                <el-card v-for="(item,index) in personnels" v-bind:key="index" style="margin: 3px auto; width: 30%;">
                    <div style="text-align: center;">
                        <div style="display: flex;align-items: center;width: 100%;">
                            <el-avatar :size="avatarSize" :id="'avatar-' + item.id"
                                       :class="{ 'audio-playing': item.playing }"
                                       :src="item.avatar"/>
                            <span style="font-size: 14px;font-weight: bold;margin-left: 8px;">{{item.name}}</span>
                        </div>
                        <div style="font-size: 12px;color: #8c939d;margin-top: 5px;">{{item.desc}}</div>
                    </div>
                    <div class="bottom clearfix" style="margin: 5px 10px;font-size: 20px;">
                        <div style="display: flex;flex-wrap: wrap;">
                            <div style="flex: 1;text-align: left;">
                                <i v-if="!item.playing" @click="openDemoUrl(item)" class="el-icon-video-play"></i>
                                <i v-if="item.playing" @click="stopDemoUrl(item)" class="el-icon-video-pause"></i>
                            </div>
                            <div>
                                <i @click="addFaYinRen(item)" class="el-icon-circle-plus"></i>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    import {v4 as uuidV4} from 'uuid';

    import {subTask} from '@/api/task'
    import {personnelList, voiceStyleList} from '@/api/personnel'

    export default {
        data() {
            return {
                fayinrenDrawer: false,
                textarea: "",
                SDPRatio: localStorage.getItem("SDPRatio") === null ? 20 : parseInt(localStorage.getItem("SDPRatio")),
                noise: localStorage.getItem("noise") === null ? 50 : parseInt(localStorage.getItem("noise")),
                noisew: localStorage.getItem("noisew") === null ? 90 : parseInt(localStorage.getItem("noisew")),
                length: localStorage.getItem("length") === null ? 100 : parseInt(localStorage.getItem("length")),
                lengthMax: 200,
                synthesisResult: '',
                isGenerateWork: false,
                generateStatusText: "",
                personnels: [],
                audioInstances: {},
                faYinRenItem: JSON.parse(localStorage.getItem("faYinRenItem")),
                avatarSize: 60,
                voiceStyle: [],
                styleName: 'excited'
            };
        },
        created() {
            this.getPersonnelList()
            this.getMSVoiceStyle()
        },
        beforeRouteLeave(to, from, next) {
            this.isGenerateWork = false;
            next();
        },
        methods: {
            downloadVoiceResult() {
                window.open(this.synthesisResult)
            },
            saveLocal(type) {
                if (type === 'SDPRatio') {
                    localStorage.setItem("SDPRatio", this.SDPRatio)
                }
                if (type === 'noise') {
                    localStorage.setItem("noise", this.noise)
                }
                if (type === 'noisew') {
                    localStorage.setItem("noisew", this.noisew)
                }
                if (type === 'length') {
                    localStorage.setItem("length", this.length)
                }
            },

            geshihua() {
                this.textarea = this.formatText(this.textarea)
            },

            formatText(text) {
                const paragraphs = text.split(/[.?!,，。]/); // 使用标点符号 . ? ! 进行分割
                const result = [];
                for (let i = 0; i < paragraphs.length; i++) {
                    const paragraph = paragraphs[i].trim(); // 去除段落两端的空格
                    if (paragraph !== '') { // 确保段落不为空
                        result.push(paragraph);
                    }
                }
                return result.join('\n'); // 使用换行符连接段落
            },

            getMSVoiceStyle() {
                voiceStyleList().then(res => {
                    if (res.data.code === 1) {
                        this.voiceStyle = Object.entries(res.data.data[0]).map(([name, desc]) => {
                            return {name, desc};
                        });
                        console.log(this.voiceStyle)
                    }
                }).catch((err) => {
                    console.log(err)
                })
            },

            addFaYinRen(item) {
                this.faYinRenItem = item;
                localStorage.setItem("faYinRenItem", JSON.stringify(item))
                this.fayinrenDrawer = false
            },
            openDemoUrl(item) {
                try {
                    this.audioInstances.pause();
                } catch (e) {
                    console.log(e)
                }
                this.personnels = this.updatePlayStatus(this.personnels, item);
                this.$forceUpdate();
                let audio = new Audio(item.demoUrl);
                this.audioInstances = audio;
                audio.play();
            },
            stopDemoUrl(item) {
                this.audioInstances.pause();
                this.personnels = this.updatePlayStatus(this.personnels, item);
                this.$forceUpdate();
            },
            updatePlayStatus(personnels, item) {
                personnels.forEach((person) => {
                    if (person.id === item.id) {
                        person.playing = !person.playing;
                    } else {
                        person.playing = false;
                    }
                });
                return personnels;
            },
            generateUUID() {
                return uuidV4();
            },

            formatTooltip(val) {
                return val / 100;
            },

            getPersonnelList() {
                personnelList().then(res => {
                    if (res.data.code === 1) {
                        this.personnels = res.data.data;
                        if (localStorage.getItem("faYinRenItem") === null) {
                            this.faYinRenItem = res.data.data[0]
                            localStorage.setItem("faYinRenItem", JSON.stringify(res.data.data[0]))
                        }
                    }
                }).catch((err) => {
                    console.log(err)
                })
            },

            publicNotify(title, message, type, position) {
                this.$notify({
                    title: title,
                    message: message,
                    type: type,
                    position: position,
                    offset: 100
                });
            },

            synthesis() {
                if (this.textarea === null || this.textarea === '') {
                    this.publicNotify("文本不能为空", "", "error", "bottom-left");
                    return;
                }
                if (this.faYinRenItem === null || this.faYinRenItem === {}) {
                    this.publicNotify("请选择发音人", "", "error", "bottom-left");
                    return;
                }
                this.synthesisResult = ''
                this.isGenerateWork = true
                this.generateStatusText = '生成中...';
                let uuid = this.generateUUID();
                subTask(this.faYinRenItem.id, this.textarea, this.SDPRatio / 100, this.noise / 100, this.noisew / 100, this.length / 100, uuid,this.styleName).then(res => {
                    console.log(res.data)
                    if (res.data.code === 1) {
                        this.synthesisResult = res.data.data.voice
                        this.generateStatusText = '生成成功';
                        this.isGenerateWork = false
                        this.publicNotify("生成成功", "请保存到本地", "success", "bottom-left");
                    }
                }).catch((err) => {
                    console.log(err)
                    this.generateStatusText = '生成异常,请重试';
                    this.isGenerateWork = false
                    this.publicNotify("生成异常", "请重试", "error", "bottom-left");
                })
            },
        },
    };
</script>

<style scoped>

    /* 当屏幕宽度小于等于某个值时，应用移动端样式 */
    @media screen and (max-width: 768px) {
        .el-dialog__wrapper /deep/ .el-dialog {
            margin-top: 18vh !important;
            width: 90% !important;
        }
    }

    .el-card /deep/ .el-card__header {
        padding: 5px 20px;
    }

    #fayinren /deep/ .el-card__body {
        padding: 3px;
    }

    .audio-playing {
        animation: pulse 1s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.1);
        }
        100% {
            transform: scale(1);
        }
    }
</style>