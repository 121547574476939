<template>
    <div class="headerBody">
        <div class="headerChPc">
            <div @click="reIndex()" class="headerFavicon">
                <el-image style="height:50px;padding-top: 5%;" src="https://tianlai.zhaoyizhe.com/tllogo.png"/>
            </div>
            <div>
                <el-header>
                    <el-menu :default-active="$route.path" @select="handleSelect" mode="horizontal" router>
                        <el-menu-item index="/"> 配音制作</el-menu-item>
                        <el-menu-item index="/user"> 个人中心</el-menu-item>
                    </el-menu>
                </el-header>
            </div>
            <div style="flex: 1;text-align: right;margin-right: 30px;">
                <el-dropdown style="display: flex;float: right;margin-top: 10px;" v-if="isLogin">
                    <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"/>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="gotoUser()"> 个人中心</el-dropdown-item>
                        <el-dropdown-item @click.native="logout()"> 退出</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                <div style="margin-top: 18px;" v-if="!isLogin">
                    <el-button style="background-color: rgb(228,168,107);color: white;font-weight: bold;" size="mini"
                               @click="goLogin()">登录/注册
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    import {fnGetToken, fnRemoveToken} from '@/util/auth'

    export default {
        data() {
            return {
                activeIndex: '1',
                avatar: null
            };
        },
        computed: {
            isLogin() {
                return (fnGetToken() !== '' && fnGetToken() !== undefined)
            }
        },
        created() {
            if(fnGetToken() === '' || fnGetToken() === undefined){
                this.$notify({
                    title: '注册送会员',
                    message: '注册后自动送一天体验会员',
                    duration: 10000,
                    position: 'bottom-right'
                });
            }
        },
        methods: {
            logout() {
                let that = this;
                fnRemoveToken()
                that.$store.commit("doLogout");
                that.goLogin()
            },
            gotoUser() {
                this.$router.push("/user", () => {
                }, () => {
                })
            },
            goLogin() {
                this.$router.push("/login").then(() => {
                    location.reload();
                }).catch(err => {
                    console.error(err);
                });
            },
            reIndex() {
                this.$router.go(0);
            },
            handleSelect(key) {
                this.$router.push(key, () => {
                }, () => {
                })
            }
        }
    }
</script>
<style>
    .headerBody {
        width: 100%;
        background-color: white;
        position: sticky;
        top: 0;
        z-index: 200;
    }

    .headerBody .headerChPc {
        display: flex;
    }

    .headerBody .headerChPc .headerFavicon {
        margin-left: 30px;
    }

    .el-menu.el-menu--horizontal {
        border-bottom: 0 !important;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }

    .el-image__error,
    .el-image__inner,
    .el-image__placeholder {
        height: 80% !important;
    }
</style>