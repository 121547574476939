<template>
    <div id="app">
        <SysMobileHeader class="mobile-header"/>
        <SysHeader class="desktop-header"/>
        <div class="cont">
            <router-view v-if="!$route.meta.keepAlive"></router-view>
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive"></router-view>
            </keep-alive>
        </div>
    </div>
</template>

<script>
    import SysHeader from '@/components/SysHeader';
    import SysMobileHeader from '@/components/SysMobileHeader';

    export default {
        name: 'AppStyle',
        components: {
            SysHeader,
            SysMobileHeader
        },
        mounted() {
            let geturl = window.location.href
            let getqys = new URLSearchParams('?' + geturl.split('?')[1])
            let aff = getqys.get('aff')
            if (aff !== null && aff !== undefined && aff !== "") {
                localStorage.setItem('aff', aff);
            }
        }
    }
</script>

<style>
    body {
        margin: 0;
        padding: 0;
        width: 100%;
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        background-color: white;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: darkgrey;
    }

    .conf {
        width: 80vw;
        margin: 0 auto;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    nav {
        padding: 30px;
    }

    nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    nav a.router-link-exact-active {
        color: #42b983;
    }

    /* 默认样式 */
    .mobile-header,
    .desktop-header {
        display: none;
    }

    /* 当屏幕宽度小于等于某个值时，应用移动端样式 */
    @media screen and (max-width: 768px) {
        .mobile-header {
            display: block;
        }
    }

    /* 当屏幕宽度大于某个值时，应用桌面端样式 */
    @media screen and (min-width: 769px) {
        .desktop-header {
            display: block;
        }
    }
</style>