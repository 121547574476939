import request from '../util/request.js'

// 发音人列表
export function personnelList() {
    return request({
        url: '/personnel/list',
        method: 'get'
    })
}

// 微软发音风格
export function voiceStyleList() {
    return request({
        url: '/voice/voice/style',
        method: 'get'
    })
}