import axios from 'axios'
import Qs from 'qs'
import router from '../router/index.js'
import {
    fnGetToken,
    fnRemoveToken
} from './auth.js'
import {
    MessageBox,
    Notification
} from 'element-ui'

// 创建一个axios实例
const service = axios.create({
    baseURL: 'https://voiceapi.zhaoyizhe.com/svs/api',
    // baseURL: 'http://127.0.0.1:7913/svs/api',
    transformRequest: [function (data) {
        return Qs.stringify(data)
    }],
    timeout: 30000
});

service.interceptors.request.use(
    config => {
        let token = fnGetToken()
        if (token) {
            config.headers['token'] = token
            config.params = {
                ...config.params,
                token: token
            };
        }
        return config
    },
    error => {
        console.log('拦截错误', error)
        return Promise.reject()
    }
)

// 添加相应拦截器
service.interceptors.response.use(response => {
    let decryptData = "";
    try {
        decryptData = JSON.parse(response.data);
    } catch (e) {
        decryptData = response.data
    }

    if (decryptData.code === 0) {
        fnRemoveToken()
        MessageBox.confirm(decryptData.message, '请登录账号', {
            confirmButtonText: '去登录',
            type: 'warning',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
        }).then(() => {
            router.push("/login", () => {
            }, () => {
            })
        }).catch(() => {
        });
    }else if (decryptData.code === -1) {
        Notification({
            title: decryptData.message,
            message: "",
            type: "error",
            position: "bottom-right",
            offset: 100
        });
    }
    return response;
}, error => {
    return Promise.reject(error);
})

export default service