import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
        keepAlive:true
    }
},
    {
        path: '/user',
        name: 'user',
        meta: {
            keepAlive:false
        },
        component: () => import('../views/SysUser.vue')
    },
    {
        path: '/login',
        name: 'login',
        meta: {
            keepAlive:true
        },
        component: () => import('../views/SysLogin.vue')
    }
]

const router = new VueRouter({
    mode: "hash",
    routes
})

export default router